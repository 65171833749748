import React, { Component } from "react";
import printJS from "print-js";
const QRcode = require("qrcode");
const jwt = require("jsonwebtoken");

class Personal extends Component {
  constructor(props) {
    super(props);
    this.QRcodeRef = React.createRef();
    this.handleBeforeButton = this.handleBeforeButton.bind(this);
    this.handlePrint = this.handlePrint.bind(this);

    this.decoded = jwt.decode(
      this.props.history.location.state["x-auth-token"]
    );
    console.log(this.decoded);
    this.url = `http://cs459.minhoeom.com/client/${this.decoded._id}`;
  }

  componentDidMount() {
    QRcode.toCanvas(this.QRcodeRef.current, this.url, (error) => {
      if (error) console.log(error);
    });
  }

  handleBeforeButton() {
    this.props.history.push("/");
  }

  handlePrint() {
    printJS("printed", "html");
  }

  render() {
    return (
      <div className="container">
        <div className="my-6">
          <button className="button is-white" onClick={this.handleBeforeButton}>
            <span className="icon is-small has-text-info">
              <i className="fas fa-chevron-left"></i>
            </span>
          </button>
        </div>

        <div className="card has-text-centered mx-6 mb-4" id="printed">
          <div className="card-image">
            <a href={this.url} target="_blank" rel="noreferrer">
              <canvas ref={this.QRcodeRef}></canvas>
            </a>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{this.decoded.corpName}</p>
                <div className="subtitle is-6">
                  {this.decoded.address1}
                  <br />
                  {this.decoded.address2}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="has-text-centered">
          <button
            className="button is-outlined is-primary"
            onClick={this.handlePrint}
          >
            Print
          </button>
        </div>
      </div>
    );
  }
}

export default Personal;
