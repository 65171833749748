import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bulma/css/bulma.css";
import Home from "./components/home";
import Personal from "./components/personal";
import Admin from "./components/admin";
import Client from "./components/client";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/client/:id" component={Client} />
      <Route path="/admin" component={Admin} />
      <Route path="/personal" component={Personal} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
