import React, { Component } from "react";
const axios = require("axios");

class Client extends Component {
  state = {
    verified: false,
    clicked: false,
    error: false,
  };

  constructor(props) {
    super(props);
    this.id = this.props.location.pathname.split("/")[2];
    this.CPref = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    if (this.state.verified) return;
    this.setState({ clicked: true });
    let data = {
      id: this.id,
      date: Date.now(),
      cellphone: this.CPref.current.value,
    };
    try {
      await axios.post("https://cs459server.herokuapp.com/api/client", data);
      this.setState({ verified: true });
    } catch (error) {
      console.log(error.response.data);
      this.setState({ error: true });
      this.setState({ clicked: false });
    }
  }

  getButtonClass() {
    if (this.state.verified) return "button is-success";
    else if (this.state.clicked) return "button is-loading";
    else if (this.state.error) return "button is-danger";
    else return "button";
  }

  getButtonText() {
    if (this.state.verified) return "You can enter now!";
    else if (this.state.error) return "Please submit your cellphone number";
    else return "submit";
  }

  render() {
    return (
      <div className="container my-6">
        <div className="columns">
          <div className="column"></div>
          <div className="column">
            <div className="field">
              <label className="label">Cellphone Number</label>
              <div className="control">
                <input
                  className="input"
                  type="tel"
                  placeholder="ex) 010-xxxx-xxxx"
                  ref={this.CPref}
                />
              </div>
            </div>
            <button
              className={this.getButtonClass()}
              onClick={this.handleSubmit}
            >
              {this.getButtonText()}
            </button>
          </div>
          <div className="column"></div>
        </div>
      </div>
    );
  }
}

export default Client;
