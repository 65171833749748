import React, { Component, Fragment } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
const axios = require("axios");

class Admin extends Component {
  state = {
    data: [],
  };
  constructor(props) {
    super(props);
    this.CPref = React.createRef();
    const now = new Date(Date.now());
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    const tomorrow = new Date(Date.now());
    tomorrow.setHours(23);
    tomorrow.setMinutes(59);
    tomorrow.setSeconds(59);
    this.date = now;
    this.date2 = tomorrow;
    this.handleBeforeButton = this.handleBeforeButton.bind(this);
    this.handleCalendar = this.handleCalendar.bind(this);
    this.handleFind = this.handleFind.bind(this);
  }

  handleBeforeButton() {
    this.props.history.push("/");
  }

  handleCalendar(value) {
    this.date = value;
  }

  async handleFind() {
    let data = {
      date: this.date,
      cellphone: this.CPref.current.value,
    };
    try {
      let response = await axios.post(
        "https://cs459server.herokuapp.com/api/admin/find",
        data,
        {
          headers: {
            "x-auth-token": this.props.history.location.state["x-auth-token"],
          },
        }
      );
      console.log(response.data);
      this.setState({ data: response.data });
    } catch (error) {
      console.log(error.response.data);
    }
  }

  getResults() {
    return this.state.data.map((candidates) => {
      return (
        <div className="my-3">
          <span className="has-text-weight-medium">
            {candidates[0].corpName} ({candidates[0].address1},{" "}
            {candidates[0].address2})
          </span>
          <br />
          {candidates.map((candidate) => {
            return (
              <Fragment>
                <span>
                  {candidate.cellphone}: {new Date(candidate.date).toString()}
                </span>
                <br />
              </Fragment>
            );
          })}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="container">
        <div className="mt-6">
          <button className="button is-white" onClick={this.handleBeforeButton}>
            <span className="icon is-small has-text-info">
              <i className="fas fa-chevron-left"></i>
            </span>
          </button>
        </div>
        <div className="has-text-centered mb-6">
          <span className="is-size-5 has-text-weight-medium has-text-black">
            ADMIN tools
          </span>
        </div>
        <div className="columns">
          <div className="column has-text-centered is-3">
            <span className="is-size-6 has-text-weight-medium has-text-black">
              Find a chain
            </span>
            <div className="field mt-4">
              <label className="label">Cellphone Number</label>
              <div className="control">
                <input
                  className="input"
                  type="tel"
                  placeholder="ex) 010-xxxx-xxxx"
                  ref={this.CPref}
                />
              </div>
            </div>
            <Calendar calendarType="US" onClickDay={this.handleCalendar} />
            <button className="button mt-2" onClick={this.handleFind}>
              Find
            </button>
          </div>
          <div className="column has-text-centered is-9">
            <span className="is-size-6 has-text-weight-medium has-text-black">
              Results
            </span>
            <div className="mt-4">{this.getResults()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
