import React, { Component } from "react";
import DaumPostcode from "react-daum-postcode";
const axios = require("axios");
const jwt = require("jsonwebtoken");

class Home extends Component {
  state = {
    isSignInActive: false,
    isSignUpActive: false,
    isAddressActive: false,
    isWrong: false,
    isWrongSU: false,
    SUReason: "Submit",
  };

  constructor() {
    super();
    this.IDref = React.createRef();
    this.IDref_SI = React.createRef();
    this.PWref = React.createRef();
    this.PWref_SI = React.createRef();
    this.CPref = React.createRef();
    this.CorpRef = React.createRef();
    this.addressInput = React.createRef();
    this.addressInputDetail = React.createRef();
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSignInSubmit = this.handleSignInSubmit.bind(this);
  }

  handleSignIn() {
    this.setState({ isSignInActive: true });
  }

  handleSignUp() {
    this.setState({ isSignUpActive: true });
  }

  handleComplete(data) {
    this.setState({ isAddressActive: false });
    this.addressInput.current.value = data.address;
  }

  async handleSubmit() {
    let data = {
      id: this.IDref.current.value,
      password: this.PWref.current.value,
      cellphone: this.CPref.current.value,
      corpName: this.CorpRef.current.value,
      address1: this.addressInput.current.value,
      address2: this.addressInputDetail.current.value,
    };
    try {
      let response = await axios.post(
        "https://cs459server.herokuapp.com/api/users/reg",
        data
      );
      let token = response.headers["x-auth-token"];
      this.props.history.push({
        pathname: "/personal",
        state: {
          "x-auth-token": token,
        },
      });
    } catch (error) {
      this.setState({ isWrongSU: true });
      this.setState({ SUReason: error.response.data });
      console.log(error.response.data);
    }
  }

  async handleSignInSubmit() {
    let data = {
      id: this.IDref_SI.current.value,
      password: this.PWref_SI.current.value,
    };
    try {
      let response = await axios.post(
        "https://cs459server.herokuapp.com/api/users/auth",
        data
      );
      let token = response.headers["x-auth-token"];
      let decoded = jwt.decode(token);
      if (decoded.isAdmin) {
        this.props.history.push({
          pathname: "/admin",
          state: {
            "x-auth-token": token,
          },
        });
      } else {
        this.props.history.push({
          pathname: "/personal",
          state: {
            "x-auth-token": token,
          },
        });
      }
    } catch (error) {
      this.setState({ isWrong: true });
      console.log(error.response.data);
    }
  }

  getModalClass() {
    if (this.state.isSignInActive || this.state.isSignUpActive) {
      return "modal is-active";
    } else {
      return "modal";
    }
  }

  getAddressClass() {
    if (this.state.isAddressActive) {
      return "modal is-active";
    } else {
      return "modal";
    }
  }

  getModalContent() {
    if (this.state.isSignUpActive) {
      return (
        <div className="box">
          <div className="field">
            <label className="label">ID</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="ID"
                ref={this.IDref}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Password"
                ref={this.PWref}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Cellphone Number</label>
            <div className="control">
              <input
                className="input"
                type="tel"
                placeholder="ex) 010-xxxx-xxxx"
                ref={this.CPref}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Corporation Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="ex) Samsung Electronics Co., Ltd."
                ref={this.CorpRef}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Address</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="ex) 경기도 수원시 영통구 삼성로 129"
                ref={this.addressInput}
                onFocus={() => this.setState({ isAddressActive: true })}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="ex) 삼성전자공업단지 (우)16677"
                ref={this.addressInputDetail}
              />
            </div>
          </div>
          <div className={this.getAddressClass()}>
            <div
              className="modal-background"
              onClick={() => this.setState({ isAddressActive: false })}
            ></div>
            <div className="modal-content box">
              <DaumPostcode onComplete={this.handleComplete} />
            </div>
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={() => this.setState({ isAddressActive: false })}
            ></button>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={
                  this.state.isWrongSU ? "button is-danger" : "button is-link"
                }
                onClick={this.handleSubmit}
              >
                {this.state.SUReason}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (this.state.isSignInActive) {
      return (
        <div className="box">
          <div className="field">
            <label className="label">ID</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="ID"
                ref={this.IDref_SI}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.handleSignInSubmit();
                  }
                }}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Password"
                ref={this.PWref_SI}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.handleSignInSubmit();
                  }
                }}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={
                  this.state.isWrong ? "button is-danger" : "button is-link"
                }
                onClick={this.handleSignInSubmit}
              >
                {this.state.isWrong ? "Invalid ID or password" : "submit"}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <div className="container">
        <div className={this.getModalClass()}>
          <div
            className="modal-background"
            onClick={() =>
              this.setState({ isSignInActive: false, isSignUpActive: false })
            }
          ></div>
          <div className="modal-content">{this.getModalContent()}</div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={() =>
              this.setState({ isSignInActive: false, isSignUpActive: false })
            }
          ></button>
        </div>
        <div className="has-text-centered my-6">
          <h1 className="is-size-4 has-text-weight-medium has-text-black">
            COVID-19 Electronic Access List System
          </h1>
        </div>
        <div className="has-text-centered">
          <button
            className="button is-outlined is-primary mr-2"
            onClick={this.handleSignIn}
          >
            Sign IN
          </button>
          <button
            className="button is-outlined is-link"
            onClick={this.handleSignUp}
          >
            Sign UP
          </button>
        </div>
      </div>
    );
  }
}

export default Home;
